import { Directive, Input, OnInit, Self } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[disabledControl]",
})
export class AppDisabledControlDirective implements OnInit {
  state = false;

  @Input() set disabledControl(state: boolean) {
    this.state = state;
    this.performAction();
  }

  constructor(@Self() private readonly ngControl: NgControl) {}

  ngOnInit() {
    this.performAction();
  }

  private performAction() {
    const action = this.state ? "disable" : "enable";
    this.ngControl.control?.[action]();
  }
}
