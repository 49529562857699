import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddNewJobCardModalComponent } from '@shared/components/modal/add-new-job-card-modal/add-new-job-card-modal.component';
import { AddWorkOrderPrerequisitesComponent } from '@shared/components/modal/add-work-order-prerequisites/add-work-order-prerequisites.component';
import { WorkOrderDetails } from '@shared/models/work-orders/workorder-details.model';
import { map, filter, Subject, takeUntil } from 'rxjs';
import { JobidCardDetailService } from '../jobid-card-detail/jobid-card-detail.service';
import { Router } from '@angular/router';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class JobCardCreationService {

  affiliate= '';
  destroy = new Subject<void>();

  constructor(private modalService: NgbModal, private jobidCardDetailService: JobidCardDetailService, private router: Router, private profileService: ProfileService) {
    this.profileService.affiliate$.subscribe((affiliate) => {
      this.affiliate = affiliate.value;
    });
  }

  openCreateJobCardModal(): void {
    const modalResult = this.modalService.open(AddNewJobCardModalComponent, { centered: true });
    modalResult.result
      .then((result: { jobCardId: string; workOrderNumber: string | undefined }) => {
        if (result.workOrderNumber) {
          this.suggestWorkOrderPrerequisites(result.jobCardId, result.workOrderNumber);
        } else {
          // No work order number was provided, we navigate to the general tab
          this.redirectToGeneralDetails(result.jobCardId);
        }
      })
      .catch(() => {
        console.warn("Creation cancelled");
      });
  }

  private suggestWorkOrderPrerequisites(jobCardId: string, workOrderNumber: string): void {
    this.jobidCardDetailService
      .getWorkOrderDetailsSilently(workOrderNumber)
      .pipe(
        map((result) => result.data.getWorkOrderDetailsAggregate),
        filter(Boolean),
      )
      .subscribe((workOrderDetails: WorkOrderDetails) => {
        if (workOrderDetails.prerequisites.length > 0) {
          const modalRef = this.modalService.open(AddWorkOrderPrerequisitesComponent, {
            centered: true,
          });
          modalRef.componentInstance.prerequisites = workOrderDetails.prerequisites;
          modalRef.componentInstance.jobCardId = jobCardId;
          modalRef.componentInstance.jobCardCreation = true;
          modalRef.componentInstance.prerequisitesAdded
            .pipe(takeUntil(this.destroy))
            .subscribe((itemAdded: boolean) => {
              if (itemAdded) {
                this.redirectToResources(jobCardId);
              } else {
                this.redirectToGeneralDetails(jobCardId);
              }
            });
        } else {
          this.redirectToGeneralDetails(jobCardId);
        }
      });
  }

  private redirectToGeneralDetails(jobCardId: string): void {
    this.destroy.next();
    this.router
      .navigateByUrl(`/jobid-card/detail/${this.affiliate}/${jobCardId}/general`)
      .catch((error) => console.error(error));
  }

  private redirectToResources(jobCardId: string): void {
    this.destroy.next();
    this.router
      .navigateByUrl(`/jobid-card/detail/${this.affiliate}/${jobCardId}/prerequisites-documents/resources`)
      .catch((error) => console.error(error));
  }
}
