import { JobGateReadiness } from "@shared/models/jobid-card/jobid-card.model";

export class ReadinessBadgeRenderer {

  static readonly render = (params: any) =>
    `<span class="badge mx-1 ${ReadinessBadgeRenderer.getActualStatusColor(params.value)}">${
      params.value ?? "Closed/Cancelled"
    }</span>`;

  static getActualStatusColor(actualStatus: string | undefined) {
    switch (actualStatus) {
      case JobGateReadiness.DRAFT:
        return "readiness-lighter-gray";
      case JobGateReadiness.READY_TO_ACTIVATE:
        return "readiness-light-gray";
      case JobGateReadiness.AWAITING_PREP_4M:
        return "readiness-lighter-orange";
      case JobGateReadiness.READY_4M:
        return "readiness-light-orange";
      case JobGateReadiness.AWAITING_PREP_5W:
      case JobGateReadiness.AWAITING_PREP_5W_ALERT:
        return "readiness-lighter-blue";
      case JobGateReadiness.READY_5W:
        return "readiness-light-blue";
      case JobGateReadiness.AWAITING_PREP_2WF:
        return "readiness-lighter-violet";
      case JobGateReadiness.READY_2WF:
        return "readiness-light-violet";
      case JobGateReadiness.SITE_EXECUTION_PREPARATION:
        return "readiness-light-green";
      default:
        return "readiness-black";
    }
  }

}
