import gql from "graphql-tag";

const GET_JCM_HIGHLIGHTS = gql`
  query ($affiliate: String, $id: Int, $flocDetectionLevel: Int = 1) {
    getResourcesOverlaps(affiliate: $affiliate, masterId: $id, flocDetectionLevel: $flocDetectionLevel) {
      scaffoldingOverlaps {
        baseFloc
        jobCardList {
          id
          jobTitle
          startDate
          endDate
        }
      }
      liftingOverlaps{
        baseFloc
        jobCardList {
          id
          jobTitle
          startDate
          endDate
        }
      }
      isolationOverlaps{
        baseFloc
        jobCardList {
          id
          jobTitle
          startDate
          endDate
        }
      }
      inhibitionOverlaps{
        baseFloc
        jobCardList {
          id
          jobTitle
          startDate
          endDate
        }
      }
      temporaryEquipmentOverlaps{
        baseFloc
        jobCardList {
          id
          jobTitle
          startDate
          endDate
        }
      }
    }
  }
`;

export { GET_JCM_HIGHLIGHTS };
