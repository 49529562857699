<mat-card class="impact-card">
  <div style="display: flex">
    <mat-icon [inline]="true" class="title-icon">graphic_eq</mat-icon>
    <h5 class="card-title">Impacts</h5>
  </div>
  <div class="horizontal-bar mb-3"></div>

  <div class="d-flex justify-content-center">
    <div class="input-group">
      <button (click)="onTabChange('disciplines')" [ngClass]="{
                      'btn-primary': selectedTab === 'disciplines'
                    }" class="btn" id="disciplines" type="button">Disciplines
      </button>
      <button (click)="onTabChange('sites')" [ngClass]="{
                      'btn-primary': selectedTab === 'sites'
                    }" class="btn" id="sites" type="button">Sites
      </button>
    </div>
  </div>

  <div class="row flex-fill ms-0 ps-0">
    <div class="col-4 mt-1 ms-0 ps-0 d-flex flex-column">
      <ag-charts-angular style="height: 100%" [options]="options"></ag-charts-angular>
    </div>
    <div class="col-8 mt-1 ms-0 ps-0 d-flex flex-column left-border">
      <h5 class="impacted-components-title"><strong style="font-size: 50px">{{ impactedValue }}</strong> {{'jcm_generalDetails.impacted' | translate}} {{impactedLabel}}</h5>

      <div class="row flex-fill" style="max-height: 80px; margin-left: 50px;">

        <div *ngFor="let data of getData(); let i = index"
             class="col-2 mt-1 ms-0 ps-0 d-flex flex-column strong-left-border"
             [style]="'color: ' + paletteColors[i]"
        >
          <span class="big-font card-content">{{data.count}}</span>
          <span class="card-content">{{data.label}}</span>
          <span class="card-content">{{percent(data.count)}}%</span>
        </div>
      </div>

    </div>
  </div>
</mat-card>
